<template>
  <div>
    <div class="d-flex align-center">
        <back v-if="!hideBack" class="mt-1"/> <slot name="actions"></slot>
    </div>
    <v-divider class="mb-5 mt-5"></v-divider>
  </div>

</template>

<script>

export default {
  name: 'AdminTitle',
  props: {
    title: String,
    icon: String,
    hideBack: Boolean
  }
}

</script>